import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const IndexPage = () => (
    <Layout>
        <SEO title="Contact" />
        <div className="m-auto">
            <h1 className="mb-16" id="success_title">Thank you!</h1>
            <p className="font-sans">Thank you very much for your contact. I will answer you shortly.</p>
            <p className="font-sans">Have a nice day!</p>
            <p className="font-sans underline"><Link to="/">Back to Home</Link></p>
        </div>
    </Layout>
)


export default IndexPage;